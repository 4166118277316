<template>
  <div class="test">
    <div id="container"></div>
    <div class="title">{{ regionName }} 称重设备分布图</div>
    <div id="myPageTop">
      <a-input-search id="tipinput" v-model="inputValue" placeholder="小区搜索" style="width: 100%;"/>
    </div>
    <div id="panel"></div>
    <a-modal v-model="visible" cancel-text="取消" ok-text="确认" title="信息窗口" width="60%" @ok="hideModal">
      <a-descriptions bordered layout="vertical" style="overflow: auto;" title="小区详情">
        <a-descriptions-item label="小区名称">
          {{ center.centerName }}
        </a-descriptions-item>
        <a-descriptions-item label="小区地址">
          {{ center.address }}
        </a-descriptions-item>
        <a-descriptions-item label="清洁屋">
          {{ center.collectionPointCode }}个
        </a-descriptions-item>
        <a-descriptions-item label="设备数量">
          {{ center.deviceCode }}个
        </a-descriptions-item>
        <a-descriptions-item label="投入使用时间">
          {{ center.startUseTime ? center.startUseTime : '(暂无)' }}
        </a-descriptions-item>
        <a-descriptions-item :span="2" label="户均指数">
          (暂无)
        </a-descriptions-item>
        <!--        <a-descriptions-item :span="3" label="使用状态">-->
        <!--          <a-badge status="processing" text="使用中"/>-->
        <!--          <br/>-->
        <!--          <img src="@/assets/image/monitor.png" style="width: 45px;height:45px;"/>-->
        <!--          <a @click="showAlert">查看监控1</a>-->
        <!--          <img src="@/assets/image/monitor.png" style="width: 45px;height:45px;"/>-->
        <!--          <a @click="showAlert">查看监控2</a>-->
        <!--          <img src="@/assets/image/monitor.png" style="width: 45px;height:45px;"/>-->
        <!--          <a @click="showAlert">查看监控3</a>-->
        <!--        </a-descriptions-item>-->
        <!--        <a-descriptions-item label="设备1">-->
        <!--          <a-badge status="processing" text="使用中"/>-->
        <!--          <a @click="showAlert">数据记录</a>-->
        <!--        </a-descriptions-item>-->
        <!--        <a-descriptions-item label="设备2">-->
        <!--          <a-badge status="processing" text="使用中"/>-->
        <!--          <a @click="showAlert">数据记录</a>-->
        <!--        </a-descriptions-item>-->
        <!--        <a-descriptions-item label="设备3">-->
        <!--          <a-badge status="processing" text="使用中"/>-->
        <!--          <a @click="showAlert">数据记录</a>-->
        <!--        </a-descriptions-item>-->
        <a-descriptions-item v-for="device in center.deviceList" :label="device.deviceName+' 设备'" :key="device">
          <a-badge :text="device.deviceStatusStr" status="processing"/>
          <a @click="showAlert">数据记录</a>
        </a-descriptions-item>
        <a-descriptions-item label="其他">
          (暂无)
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>
<script>
import MapLoader from '@/utils/remoteLoad.js'
import {mapState} from "vuex";
import {queryMapCenter, queryMapCenterList} from "@/api/map";
import _ from 'lodash'

export default {
  name: 'test',
  data() {
    return {
      inputValue: '',
      visible: false,
      map: null,
      mapCenter: '',
      district: null,
      polygons: [],
      bounds: '',
      opts: {
        subdistrict: 0,
        extensions: 'all',
        level: 'district'
      },
      centerList: [],
      center: {},
    }
  },
  async mounted() {
    await this.queryMapCenterList()
    this.mapInit()
  },
  methods: {
    mapInit() {
      let that = this
      MapLoader().then(AMap => {
        this.$message.success('地图加载成功')
        that.map = new AMap.Map('container', {
          resizeEnable: true,
          mapStyle: 'amap://styles/fresh',
          zoom: 16.8,
        })
        that.map.setCity(this.regionName)
        that.mapCenter = that.map.getCenter()
        // var autoOptions = {
        //     input: "tipinput"
        // };
        AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.ControlBar'], () => {
          that.map.addControl(new AMap.ControlBar());
          // 实例化Autocomplete
          var autoOptions = {
            //city 限定城市，默认全国
            city: '全国',
            input: "tipinput"
          }
          var autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search('', function (status, result) {
            // 搜索成功时，result即是对应的匹配数据
            // console.log(result)
          })
          autoComplete.on('select', function (e) {
            // var placesearch = new AMap.PlaceSearch({
            //     map: that.map
            // })
            // placesearch.search(e.poi.name)
            // that.map.setCenter(e.poi.location)
            var placeSearch = new AMap.PlaceSearch({
              pageSize: 5, // 单页显示结果条数
              pageIndex: 1, // 页码
              city: '', // 兴趣点城市
              citylimit: true,  //是否强制限制在设置的城市内搜索
              map: that.map, // 展现结果的地图实例
              panel: "panel", // 结果列表将在此容器中进行展示。
              autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            });
            //关键字查询
            placeSearch.search(e.poi.name);
          })

        })
        /**
         *定位到当前区域  传入当前区域名称
         */
        AMap.service('AMap.DistrictSearch', () => {//回调函数
          //实例化DistrictSearch
          that.district = new AMap.DistrictSearch(that.opts);
          that.district.setLevel(that.opts.level)
          that.district.search(this.regionName, function (status, result) { //传入区域名称
            console.log(result)
            that.map.remove(that.polygons)
            that.polygons = []
            that.bounds = result.districtList[0].boundaries //获取区域边界数据点
            if (that.bounds) {
              for (let i = 0; i < that.bounds.length; i++) {
                let polygon = new AMap.Polygon({
                  strokeWeight: 1,
                  path: that.bounds[i],
                  fillOpacity: 0.4,
                  fillColor: '#ee2200',
                  strokeColor: '#F33'
                })
                that.polygons.push(polygon)
              }
            }
            that.map.add(that.polygons)
            that.map.setFitView(that.polygons)
            that.map.setCenter(result.districtList[0].center)
          })

          let markerList = []
          _.forEach(this.centerList, center => {
            if (!(center.longitude && center.latitude)) {
              return
            }

            let position = [center.longitude, center.latitude]
            const marker = new AMap.Marker({ //给每个点位添加图层覆盖物标识
              position: position,
              icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              anchor: 'bottom-center',
              offset: new AMap.Pixel(0, 0),
              content: `<div style="display: flex;flex-direction: column;width: 100px;height: 60px;color: white;">
                          <div style="background-color:#53A849;padding: 5px;height: 80px;box-shadow:2px 2px 5px rgba(68,53,53,0.34);border-radius: 5px;">${center.centerName}</div>
                          <div style="height: 30px;align-self: center;">
                              <div style="height: 100%;background-color:#0F7303;width:4px;box-shadow:2px 2px 5px rgba(68,53,53,0.34); "></div>
                          </div>
                          <div style="width: 10px;height: 6px;background: #0F7303;border-radius: 50%;align-self: center;margin-top: -3px;"></div>
                          <div style="width: 31px;height: 20px;background: #53A849;opacity: 0.4;border-radius: 50%;align-self: center;margin-top: -13px;"></div>
                          </div>`
            });
            // marker.setMap(that.map);
            marker.setTitle('小区名称：' + center.centerName);
            // 设置label标签
            // label默认蓝框白底左上角显示，样式className为：amap-marker-label
            marker.setLabel({
              direction: 'right',
              offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
              // content: "<div class='info' @click="  + ">小区名称" + i + ",已有设备 0 个</div>", //设置文本标注内容
              testLabel: '1'
            });
            marker.on('click', () => {
              this.showModal(center.centerCode)
            })
            markerList.push(marker)
          })

          new AMap.MarkerClusterer(that.map, markerList)


          // function markerClick(e) {
          //   var info = []
          //   info.push("<div class='input-card content-window-card'><div></div> ");
          //   info.push("<div style=\"padding:7px 0px 0px 0px;\"><h4</h4>");
          //   info.push("<br/><p class='input-item'>已有清洁屋 : 4个 <br/>  设备: 4个</p>");
          //   // info.push("<p class='input-item'>地址 :XXX</p></div><input type='button' value='获取设备详情' onclick='+ getLngLat()'/> </div>");
          //   info.push("<p class='input-item'>地址 :XXX</p></div><p class='input-item' onClick=" + that.showModal(e) + "/> </div>");
          //
          //   let infoWindow = new AMap.InfoWindow({
          //     content: info.join(""),
          //     anchor: 'top-left',
          //     offset: new AMap.Pixel(0, -30)
          //   });
          //   infoWindow.open(that.map, e.target.getPosition());
          // }
        })
      }, e => {
        this.$message.error('地图加载失败')
      })
    },
    /**
     * 绘制区域边界  -- 以吴中区为例
     */
    drawBounds() {
      let that = this
      this.district = new AMap.DistrictSearch(this.opts)
      this.district.setLevel(this.opts.level)
      this.district.search(this.regionName, function (status, result) {
        that.map.remove(that.polygons)
        that.polygons = []
        that.bounds = result.districtList[0].boundaries
        if (bounds) {
          for (let i = 0; i < that.bounds.length; i++) {
            let polygon = new AMap.Polygon({
              strokeWeight: 1,
              path: that.bounds[i],
              fillOpacity: 0.4,
              fillColor: '#80d8ff',
              strokeColor: '#0091ea'
            })
            that.polygons.push(polygon)
          }
        }
        that.map.add(that.polygons)
        that.map.setFitView(that.polygons)
      })
    },
    showAlert() {
      alert('敬请期待')
    },
    async showModal(centerCode) {

      this.center = (await queryMapCenter({
        centerCode
      })).result

      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    gotoCity() {

    },
    async queryMapCenterList() {
      let data = await queryMapCenterList()

      this.centerList = data.result
    },
  },
  computed: mapState({
    regionName: state => state.user.module.regionName
  })
}
</script>

<style lang="scss">
.test {
  width: 100%;
  height: 100%;
  position: relative;

  #panel {
    position: absolute;
    background-color: white;
    max-height: 90%;
    overflow-y: auto;
    top: 84px;
    left: 19px;
    width: 280px;
  }

  #myPageTop {
    position: absolute;
    top: 3%;
    left: 1%;
    height: 41px;
    width: 252px;

    .ant-input-search {
      width: 100%;
      height: 100%;
    }

    .ant-input-affix-wrapper .ant-input {
      height: 100%;
    }
  }

  .title {
    position: absolute;
    top: 2%;
    left: 50%;
    padding: 0.5em 1em;
    height: auto;
    border-radius: 2px;
    font-weight: 500;
    width: 307px;
    margin-left: -153px;
    text-align: center;
    touch-action: none;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    transform: translate(0px, 10px);
  }

  #container {
    width: 100%;
    height: 100%;
  }

  .amap-icon img {
    width: 25px;
    height: 34px;
  }

  .amap-marker-label {
    border-color: #fff;
    background-color: rgb(246, 137, 38);
    padding: 5px;
    min-width: 100px;
    color: #fff;
  }

  .info {
    position: relative;
    margin: 0;
    top: 0;
    right: 0;
    min-width: 0;
  }
}
</style>
