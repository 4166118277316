import service from './network';

export function queryMapCenterList() {
    return service.request({
        url: '/area/map/center',
        method: 'get',
    })
}

export function queryMapCenter(data) {
    return service.request({
        url: '/area/map/query/center',
        method: 'get',
        params: data
    })
}

export function getCenterMapInfoApi(data) {
    return service.request({
        url: '/screen/area/map/center',
        method: 'get',
        params: data
    })
}

export function getCenterMapDetailApi(data) {
    return service.request({
        url: '/screen/area/map/query/center',
        method: 'get',
        params: data
    })
}

/**
 *地图-计量
 * @param {} data
 * @returns
 */
export function queryMeteringByCodeApi(data) {
    return service.request({
        url: '/screen/comprehensive/metering/code',
        method: 'get',
        params: data,
        headers: {'moduleToken': data}
    })
}

/**
 *地图-街道
 * @param {} data
 * @returns
 */
export function queryMeteringDetailsApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/metering/details',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *地图-小区坐标
 * @param {} data
 * @returns
 */
export function queryMeteringCentersApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/center/position',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *地图-趋势图
 * @param {} data
 * @returns
 */
export function queryMeteringPartApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/metering/part',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *地图-街道柱形图
 * @param {} data
 * @returns
 */
export function queryMeteringStreetApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/metering/type/street',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *地图-四分类采集
 * @param {} data
 * @returns
 */
export function queryMeteringTypeApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/metering/type',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *地图-小区详情
 * @param {} data
 * @returns
 */
export function queryMeteringCenterDetailApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/center/info',
        method: 'get',
        params: data,
        headers: {'moduleToken': code}
    })
}

/**
 *地图-小区详情
 * @param {} data
 * @returns
 */
export function queryMeteringCenterDetailTypeApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/metering/center/type',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

export function queryMeteringCenterDetailPartApi(data, code) {
    return service.request({
        url: '/screen/comprehensive/metering/part',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

export function queryRegionPolyLine(data) {
    return service.request({
        url: '/screen/area/map/plot',
        method: 'get',
        params: data
    })
}



